import { useEffect, useState } from 'react'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { nanoid } from 'nanoid'

import Questions from '@components/survey/questions'
import { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import { useRouter } from 'next/router'
import { LOCAL_STORAGE } from '@constants/local-storage'
import { showConfettiAnimation } from '@lib/show-confetti-animation'
import { ROUTES } from '@constants/routes'
import {
  Container,
  DotIndicators,
  ProgressBar,
  ArrowNavigator,
  PreviewBanner,
} from '@components/survey'
import { Loader } from '@components/loader'
import { PLAUSIBLE_EVENTS } from '@constants/plausible-events'
import { usePlausible } from '@hooks/use-plausible'
import ValidationProvider, {
  ResponseError,
  useValidation,
} from '@components/survey/validation-provider'
import { Question } from '@prisma/client'
import { QuestionType } from '@components/admin/editor/types'
import { storage } from '@lib/init-firebase'
import toast from 'react-hot-toast'
import { Gradient } from '@utils/gradient'

const ERROR_MESSAGE_MAP = {
  [ResponseError.REQUIRED]:
    'This is required. Please answer before proceeding.',
  [ResponseError.INVALID_EMAIL]:
    'Invalid email. Please provide a valid email before proceeding.',
  [ResponseError.INVALID_WEBSITE]:
    'Invalid website. Please provide a valid website before proceeding.',
}

const uploadFile = async (blob: Blob) => {
  try {
    const fileRef = ref(storage, nanoid())
    const result = await uploadBytes(fileRef, blob)
    return await getDownloadURL(result.ref)
  } catch (error) {
    console.log({ error })
    toast.error(
      'Oops. We cannot submit your form for some reason. Please try again.',
    )
  }
}

const parseResponses = async ({
  responses,
  questions,
}: {
  responses: Record<string, any>
  questions: Question[]
}) => {
  const responsesArr = await Promise.all(
    Object.entries(responses).map(async ([questionId, response]) => {
      const question = questions.find((q) => q.id === questionId)
      const isFileUploader = question.type === QuestionType.FILE_UPLOADER

      if (!isFileUploader) return { questionId, response }

      const downloadURL = await uploadFile(response as Blob)
      return {
        questionId,
        response: downloadURL,
      }
    }, {}),
  )

  return responsesArr.reduce(
    (acc, { questionId, response }) => ({
      ...acc,
      [questionId]: response,
    }),
    {},
  )
}

type Props = {
  redirectUrl?: string
  questions: any[]
}
const SurveyPage: NextPage<Props> = ({ redirectUrl, questions = [] }) => {
  useEffect(() => {
    const gradient = new Gradient()

    if (gradient) {
      // @ts-ignore
      gradient.initGradient('#gradient-canvas-form')
    }
  }, [])

  return <QuestionContent questions={questions} redirectUrl={redirectUrl} />
}

export default SurveyPage

const QuestionContent = ({ questions, redirectUrl }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [responses, setResponses] = useState<Record<string, any>>(() => {
    return questions.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: '',
      }),
      {},
    )
  })
  const router = useRouter()
  const plausible = usePlausible()
  const { validationStatus } = useValidation()

  const createResponse = async (body: any) => {
    const queryParams = router.query
    try {
      await fetch(ROUTES.API.CREATE_RESPONSE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...body, queryParams }),
      })
    } catch (error) {
      alert("The response couldn't be saved. Please try again later.")
    }
  }

  const isInitialPage = currentPage === 0
  const isPreview = (router.query.preview as string) === 'true'

  const getParsed = (str) => {
    try {
      return JSON.parse(str)
    } catch (error) {
      return {}
    }
  }

  if (isPreview) {
    // load questions from local storage if available
    const localQuestions = localStorage.getItem(LOCAL_STORAGE.QUESTIONS)
    if (localQuestions) {
      const parsed = getParsed(localQuestions)
      questions = parsed?.state?.questions || []
    }
  }

  const totalQuestions = questions.length
  const totalPages = totalQuestions + 2
  const isLastPage = totalPages === currentPage + 1
  const isFirstPage = currentPage === 0
  const isLastQuestion = currentPage === totalPages - 2

  const scrollIndicator = ((currentPage + 1) / totalPages) * 100

  const handleNext = async () => {
    const currentQuestion = questions[currentPage - 1]
    const { id } = currentQuestion || {}
    const isValid = validationStatus[id]?.isValid || false
    const error = validationStatus[id]?.error
    const canGoNext = !isLastPage // && isValid

    if (isInitialPage) {
      plausible(PLAUSIBLE_EVENTS.START_FORM)
    }

    if (!isFirstPage && !canGoNext) {
      toast.error('There was an error.')
      return
    }

    if (isLastQuestion) {
      if (!isPreview) {
        const parsedResponses = await parseResponses({ questions, responses })
        console.log(parsedResponses)
        createResponse({ responses: parsedResponses, id: router.query.id })
      }
      setIsSubmitted(true)
      showConfettiAnimation()
    }

    setCurrentPage((st) => st + 1)
  }
  const handlePrev = () => {
    if (isFirstPage) return
    setCurrentPage((st) => st - 1)
  }

  if (router.isFallback) {
    console.log({ questions })
    return (
      <Container>
        <Loader />
      </Container>
    )
  }

  if (totalQuestions === 0) {
    return (
      <Container>
        <h2 className="text-lg">There are no questions here. 😢</h2>
      </Container>
    )
  }

  return (
    <Container scrollIndicator={scrollIndicator}>
      {isPreview && <PreviewBanner />}
      {!isInitialPage && (
        <>
          <DotIndicators
            {...{ totalQuestions, currentPage, setCurrentPage, isSubmitted }}
          />
          <ArrowNavigator
            {...{
              handlePrev,
              handleNext,
              isFirstPage,
              isLastPage,
              isSubmitted,
            }}
          />
        </>
      )}
      <Questions
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        questions={questions}
        handleNext={handleNext}
        responses={responses}
        setResponses={setResponses}
        redirectUrl={redirectUrl}
      />
    </Container>
  )
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    // TODO: get the survey ids from the database
    paths: [],

    // see: https://nextjs.org/docs/api-reference/data-fetching/get-static-paths#when-is-fallback-true-useful
    fallback: true,
  }
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const { id } = params as Record<string, string>
  const prisma = (await import('@lib/prisma')).default

  const form = await prisma.form.findUnique({
    where: {
      publicId: id,
    },
    select: {
      redirectUrl: true,
      questions: {
        select: {
          id: true,
          type: true,
          prompt: true,
          description: true,
          options: true,
          properties: true,
        },
        orderBy: {
          properties: {
            order: 'asc',
          },
        },
      },
    },
  })

  // see: https://nextjs.org/docs/api-reference/data-fetching/get-static-props#notfound
  if (!form) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      // pass the questions to the page
      questions: form.questions,
      redirectUrl: form.redirectUrl,
    },
    // re-generate the post at most once every 10 seconds if a request comes in
    revalidate: 10,
  }
}
