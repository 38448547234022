import { FC, MouseEventHandler, ReactElement, ReactNode } from 'react'

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
  bgColor?: string
  textColor?: string
  padding?: string
  borderRadius?: string
  showIcon?: boolean
  disabled?: boolean
  children?: ReactNode
}

export const Button: FC<Props> = ({
  className,
  children,
  onClick,
  disabled,
  showIcon = true,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${className} glass-button`}
      role="button"
    >
      {children}
    </button>
  )
}
