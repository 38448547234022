export enum QuestionType {
  SHORT_TEXT = 'short-text',
  LONG_TEXT = 'long-text',
  MULTI_CHOICE = 'multi-choice',
  YES_NO = 'yes-no',
  WEBSITE = 'website',
  PHONE = 'phone',
  EMAIL = 'email',
  FILE_UPLOADER = 'file-uploader',
  NUMBER = 'number',
}

export type Option = {
  id: string
  value: string
}

export type Question = {
  id: string
  prompt: string
  description?: string
  type: QuestionType
  options?: Option[]
  placeholder?: string
  properties?: {
    order: number
    isRequired: boolean
    placeholder: string
    isMultipleSelectionAllowed?: boolean
    isOtherOptionAllowed?: boolean
    isMaxCharactersEnabled?: boolean
    isPlaceholderEnabled?: boolean
    maxCharacters?: number
  }
}
