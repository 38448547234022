import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useEffect,
  useRef,
} from 'react'
import autosize from 'autosize'
import ShortcutHint from '@components/shortcut-hint'
import {
  ResponseError,
  useValidation,
  ValidationStatus,
} from '../validation-provider'

type Props = {
  questionId: string
  properties: any
  value: string
  onChange: (newValue: string) => void
  autoFocus?: boolean
}

const LongText: FC<Props> = ({
  questionId,
  properties,
  value,
  onChange,
  autoFocus,
}) => {
  const { placeholder, maxCharacters, isMaxLengthSpecified } = properties
  const ref = useRef<HTMLTextAreaElement>(null)

  const { isRequired } = properties
  const { setValidationStatus } = useValidation()

  useEffect(() => {
    if (autoFocus) ref.current?.focus()
  }, [autoFocus])

  useEffect(() => {
    autosize(ref.current)
  }, [])

  useEffect(() => {
    if (isRequired) {
      setValidationStatus({
        id: questionId,
        newStatus: ValidationStatus.ERROR,
        error: ResponseError.REQUIRED,
      })
    } else {
      setValidationStatus({ id: questionId, newStatus: ValidationStatus.VALID })
    }
  }, [isRequired, questionId, setValidationStatus])

  const handleInputChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const value = e.target.value
    onChange(value)

    if (isRequired) {
      if (!value) {
        setValidationStatus({
          id: questionId,
          newStatus: ValidationStatus.ERROR,
          error: ResponseError.REQUIRED,
        })
        return
      }

      setValidationStatus({
        id: questionId,
        newStatus: ValidationStatus.VALID,
      })
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    const isEnterKeyPressed = e.key === 'Enter'
    const isShiftKeyPressed = e.shiftKey
    if (isEnterKeyPressed && !isShiftKeyPressed) return e.preventDefault()
  }

  return (
    <div className="flex flex-col items-center justify-center w-full gap-y-3">
      <textarea
        ref={ref}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        maxLength={isMaxLengthSpecified ? maxCharacters : undefined}
        rows={1}
        className="bg-transparent border-b-2 outline-none border-t-0 border-l-0 border-r-0 text-lg max-w-lg w-full transition-all duration-100 focus:outline-none focus:ring-transparent border-gray-300 hover:border-gray-600 focus:border-gray-900 h-min"
        onKeyDown={handleKeyDown}
      />
      <ShortcutHint className="text-gray-600">
        Press <b>Shift ⇧ + Enter ↵</b> for line break
      </ShortcutHint>
    </div>
  )
}

export default LongText
