import { useRef, FC, useCallback } from 'react'

import { QuestionType } from '@components/admin/editor/types'
import useIntersectionObserver from '@hooks/use-intersection-observer'
import ShortText from './short-text'
import LongText from './long-text'
import MultiChoice from './multi-choice'
import { useKeydown } from '@hooks/use-keydown'
import { Button } from '@components/button'
import ShortcutHint from '@components/shortcut-hint'
import NumberInput from './number-input'
import EmailInput from './email-input'
import WebsiteInput from './website-input'
import PhoneInput from './phone-input'
import YesNoInput from './yes-no-input'
import FileUploader from './file-uploader'
import { Tooltip } from '@geist-ui/core'

type Props = {
  question: any
  response: any
  handleResponseChange: (value: any) => void
  isLastPage: boolean
  handleEnter: () => void
}

const SurveyQuestion: FC<Props> = ({
  question,
  response,
  handleResponseChange,
  isLastPage,
  handleEnter,
}) => {
  const ref = useRef()
  const entryScreen1 = useIntersectionObserver(ref, {})
  const isVisible = !!entryScreen1?.isIntersecting
  const { properties, prompt } = question

  useKeydown({
    onKeyDown({ isEnterKeyPressed, isShiftKeyPressed }) {
      const isLongText = question.type === QuestionType.LONG_TEXT

      if (isLongText) {
        if (isEnterKeyPressed && !isShiftKeyPressed) {
          handleEnter()
        }
        return
      }

      if (isEnterKeyPressed) handleEnter()
    },
    stopListening: !isVisible,
  })

  const renderInput = () => {
    switch (question.type) {
      case QuestionType.SHORT_TEXT:
        return (
          <ShortText
            questionId={question.id}
            properties={properties}
            value={response}
            onChange={handleResponseChange}
            autoFocus={isVisible}
          />
        )
      case QuestionType.LONG_TEXT:
        return (
          <LongText
            questionId={question.id}
            properties={properties}
            value={response}
            onChange={handleResponseChange}
            autoFocus={isVisible}
          />
        )
      case QuestionType.MULTI_CHOICE:
        return (
          <MultiChoice
            question={question}
            selected={response}
            setSelected={handleResponseChange}
          />
        )

      case QuestionType.NUMBER:
        return (
          <NumberInput
            questionId={question.id}
            properties={properties}
            value={response}
            onChange={handleResponseChange}
            autoFocus={isVisible}
          />
        )
      case QuestionType.EMAIL:
        return (
          <EmailInput
            questionId={question.id}
            properties={properties}
            value={response}
            onChange={handleResponseChange}
            autoFocus={isVisible}
          />
        )
      case QuestionType.WEBSITE:
        return (
          <WebsiteInput
            questionId={question.id}
            properties={properties}
            value={response}
            onChange={handleResponseChange}
            autoFocus={isVisible}
          />
        )
      case QuestionType.PHONE:
        return (
          <PhoneInput
            questionId={question.id}
            properties={properties}
            value={response}
            onChange={handleResponseChange}
          />
        )
      case QuestionType.YES_NO:
        return (
          <YesNoInput
            questionId={question.id}
            value={response}
            onChange={handleResponseChange}
          />
        )
      case QuestionType.FILE_UPLOADER:
        return (
          <FileUploader
            questionId={question.id}
            value={response}
            onChange={handleResponseChange}
          />
        )
    }
  }

  const isRequired = question?.properties?.isRequired

  return (
    <div
      ref={ref}
      className="flex flex-col items-center justify-center w-full h-full max-w-4xl px-4 mx-auto text-center gap-y-8 md:gap-y-12 lg:gap-y-16 animate-fade-in"
    >
      <div>
        <h1 className="text-xl md:text-3xl lg:text-4xl">
          {prompt}{' '}
          {isRequired && (
            <Tooltip text="This is required. Please answer before proceeding">
              <span className="text-pink-500">*</span>
            </Tooltip>
          )}
        </h1>
        {question?.description && (
          <p className="text-xl md:text-2xl font-thin mt-5 w-full text-start text-gray-600">
            {question?.description}
          </p>
        )}
      </div>
      {renderInput()}
      <div className="flex justify-center items-center">
        <Button onClick={handleEnter}>
          {isLastPage ? 'Submit' : 'Continue'}
        </Button>
        <ShortcutHint />
      </div>
    </div>
  )
}

export default SurveyQuestion
