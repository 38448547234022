import { ChangeEventHandler, ReactElement, ReactNode, useEffect } from 'react'
import { useValidation, ValidationStatus } from '../validation-provider'

enum YesNo {
  YES = 'yes',
  NO = 'no',
}

const YES_NO_INPUT_NAME = 'yes-no'

const Radio = ({
  id,
  name,
  value,
  children,
  defaultChecked = false,
  disabled = false,
  onChange = () => {},
}: {
  id: string
  name: string
  value: string
  children: ReactNode
  defaultChecked?: boolean
  disabled?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
}): ReactElement => {
  return (
    <div className="flex items-center">
      <input
        id={id}
        name={name}
        type="radio"
        defaultChecked={defaultChecked}
        className="focus:ring-indigo-500 active:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
      <label
        htmlFor={id}
        className="ml-3 block text-sm font-medium text-gray-700"
      >
        {children}
      </label>
    </div>
  )
}

type Props = {
  questionId?: string
  onChange?: (value: YesNo) => void
  value?: YesNo
  className?: string
  disabled?: boolean
}
const YesNoInput = ({
  questionId,
  onChange = () => {},
  className = '',
  disabled = false,
}: Props): ReactElement => {
  const { setValidationStatus } = useValidation()

  useEffect(() => {
    onChange(YesNo.YES)
  }, [onChange])

  useEffect(() => {
    setValidationStatus({ id: questionId, newStatus: ValidationStatus.VALID })
  }, [questionId, setValidationStatus])

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value as YesNo
    onChange(value)
  }

  return (
    <div
      className={`space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 ${className}`}
    >
      <Radio
        id={YesNo.YES}
        name={YES_NO_INPUT_NAME}
        value={YesNo.YES}
        defaultChecked={!disabled}
        disabled={disabled}
        onChange={handleChange}
      >
        Yes
      </Radio>
      <Radio
        id={YesNo.NO}
        name={YES_NO_INPUT_NAME}
        value={YesNo.NO}
        disabled={disabled}
        onChange={handleChange}
      >
        No
      </Radio>
    </div>
  )
}

export default YesNoInput
