import { Button } from '@components/button'
import { GradientText } from '@components/common/gradient-text'
import useIntersectionObserver from '@hooks/use-intersection-observer'
import { FC, useRef } from 'react'

type Props = {
  redirectUrl?: string
}

const SuccessPage: FC<Props> = ({ redirectUrl }) => {
  const ref = useRef()
  const entryScreen1 = useIntersectionObserver(ref, {})
  const isVisible = !!entryScreen1?.isIntersecting

  const handleRedirect = () => {
    window.top.location.href = redirectUrl
  }

  return (
    <div
      ref={ref}
      className="flex flex-col items-center justify-center w-full h-full max-w-4xl px-4 mx-auto text-center gap-y-8 md:gap-y-12 lg:gap-y-16"
    >
      <div className="flex flex-col justify-center items-center mx-auto gap-y-3">
        <h1 className="text-xl md:text-3xl lg:text-5xl lg:leading-tight">
          <span className="text-violet-800">
            <GradientText>Your response has been submitted.</GradientText>
          </span>
          <br />
          <span>Thanks for your time! 😊</span>
        </h1>
        {redirectUrl && <Button onClick={handleRedirect}>Continue</Button>}
      </div>
    </div>
  )
}

export default SuccessPage
