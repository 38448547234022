export const PlusIcon = ({ width = 'w-8', height = 'h-8' }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`${width || 'w-8'} ${height || 'h-8'}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={3}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
  </svg>
)
