import {
  ChangeEventHandler,
  FC,
  useEffect,
  useRef,
  lazy,
  Suspense,
  useState,
} from 'react'
import {
  ResponseError,
  useValidation,
  ValidationStatus,
} from '../validation-provider'

type Props = {
  className?: string
  questionId?: string
  properties?: any
  value?: string
  onChange?: (newValue: string) => void
  autoFocus?: boolean
  disabled?: boolean
}

const CallingCodes = lazy(() => import('./calling-codes'))

const Chevron: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 text-gray-500 group-hover:text-gray-700 duration-200 transition-colors"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
      clipRule="evenodd"
    />
  </svg>
)

const PhoneInput: FC<Props> = ({
  className = '',
  questionId = '',
  properties = {},
  onChange = () => {},
  autoFocus = false,
  disabled = false,
}) => {
  const { isRequired, placeholder } = properties
  const ref = useRef<HTMLInputElement>(null)
  const [phone, setPhone] = useState({
    callingCode: '93',
    phoneNumber: '',
  })

  const { setValidationStatus } = useValidation()

  useEffect(() => {
    if (autoFocus) ref.current?.focus()
  }, [autoFocus])

  useEffect(() => {
    if (isRequired) {
      setValidationStatus({
        id: questionId,
        newStatus: ValidationStatus.ERROR,
        error: ResponseError.REQUIRED,
      })
    } else {
      setValidationStatus({ id: questionId, newStatus: ValidationStatus.VALID })
    }
  }, [isRequired, questionId, setValidationStatus])

  useEffect(() => {
    const formattedPhoneNumber = `+${phone.callingCode} ${phone.phoneNumber}`
    onChange(formattedPhoneNumber)
  }, [phone, onChange])

  const validateInput = (state: {
    callingCode: string
    phoneNumber: string
  }) => {
    if (isRequired) {
      if (!state.phoneNumber) {
        setValidationStatus({
          id: questionId,
          newStatus: ValidationStatus.ERROR,
          error: ResponseError.REQUIRED,
        })
        return
      }

      setValidationStatus({
        id: questionId,
        newStatus: ValidationStatus.VALID,
      })
    }
  }

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const phoneNumber = e.target.value

    const numberRegex = /[0-9]+/g
    const isValidNumber = numberRegex.test(phoneNumber)

    if (!isValidNumber && phoneNumber) return

    setPhone((prevState) => {
      const newState = {
        ...prevState,
        phoneNumber,
      }

      validateInput(newState)

      return newState
    })
  }

  const handleCallingCodeChange: ChangeEventHandler<HTMLSelectElement> = (
    e,
  ) => {
    const callingCode = e.target.value
    setPhone((prevData) => ({
      ...prevData,
      callingCode,
    }))
  }

  return (
    <div
      className={`${className} flex items-center bg-transparent max-w-lg w-full`}
    >
      <div className="bg-transparent flex items-center group">
        <select
          id="country"
          name="country"
          autoComplete="country"
          className="text-lg text-gray-500 group-hover:text-gray-700 duration-200 transition-colors bg-transparent appearance-none rounded-xl"
          onChange={handleCallingCodeChange}
          value={phone.callingCode}
          disabled={disabled}
        >
          <Suspense fallback={null}>
            <CallingCodes />
          </Suspense>
        </select>
      </div>
      <input
        type="text"
        name="phone-number"
        id="phone-number"
        className="bg-transparent border-b-2 outline-none border-t-0 border-l-0 border-r-0 text-lg max-w-lg w-full transition-all duration-100 focus:outline-none focus:ring-transparent border-gray-300 hover:border-gray-600 focus:border-gray-900"
        placeholder={placeholder}
        onChange={handleInputChange}
        value={phone.phoneNumber}
        disabled={disabled}
      />
    </div>
  )
}

export default PhoneInput
