import {
  FC,
  createContext,
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react'

export enum ResponseError {
  REQUIRED = 'required',
  INVALID_EMAIL = 'invalidEmail',
  INVALID_WEBSITE = 'invalidWebsite',
}

export enum ValidationStatus {
  IDLE = 'idle',
  VALIDATING = 'validating',
  VALID = 'validated',
  ERROR = 'error',
}

type ID = string
type ValidationContextValues = {
  validationStatus: Record<
    ID,
    {
      isIdle: boolean
      isValid: boolean
      isValidating: boolean
      isError: boolean
      error: ResponseError
    }
  >
  setValidationStatus: (params: {
    id: ID
    newStatus: ValidationStatus
    error?: ResponseError
  }) => void
}

const ValidationContext = createContext<ValidationContextValues>({
  validationStatus: {},
  setValidationStatus: () => {},
})

type Props = {
  children?: ReactNode
  questions: any[]
}

const ValidationProvider: FC<Props> = ({ children, questions }) => {
  const [questionsValidationStatus, setQuestionsValidationStatus] = useState<
    Record<
      ID,
      {
        status: ValidationStatus
        error?: ResponseError
      }
    >
  >(() => {
    return questions.reduce(
      (acc, question) => ({
        ...acc,
        [question.id]: {
          status: ValidationStatus.IDLE,
        },
      }),
      {},
    )
  })

  const validationStatus = Object.entries(questionsValidationStatus).reduce(
    (acc, [key, value]) => {
      const isIdle = value.status === ValidationStatus.IDLE
      const isValidating = value.status === ValidationStatus.VALIDATING
      const isValid = value.status === ValidationStatus.VALID
      const isError = value.status === ValidationStatus.ERROR

      return {
        ...acc,
        [key]: {
          isIdle,
          isValidating,
          isValid,
          isError,
          error: value.error,
        },
      }
    },
    {},
  )

  const setValidationStatus = useCallback(
    ({
      id,
      newStatus,
      error,
    }: {
      id: ID
      newStatus: ValidationStatus
      error?: ResponseError
    }) => {
      setQuestionsValidationStatus((prevState) => ({
        ...prevState,
        [id]: {
          status: newStatus,
          ...(error ? { error } : {}),
        },
      }))
    },
    [],
  )

  return (
    <ValidationContext.Provider
      value={{
        validationStatus,
        setValidationStatus,
      }}
    >
      {children}
    </ValidationContext.Provider>
  )
}

export default ValidationProvider

export const useValidation = () => {
  const { setValidationStatus, ...values } = useContext(ValidationContext)

  return {
    ...values,
    setValidationStatus,
  }
}
