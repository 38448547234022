import { ChevronIcon, WarningIcon } from '@components/icons'

export const ProgressBar = ({ scrollIndicator }) => {
  if (scrollIndicator)
    return (
      <div className="fixed top-4 z-50 w-full h-[8px] bg-white bg-opacity-20 rounded-full max-w-[50vw] mx-auto shadow-sm">
        <div
          className="h-[8px] transition-all duration-1000 ease-in-out bg-white rounded-full"
          style={{ width: `${scrollIndicator}%` }}
        />
      </div>
    )
}

export const Container = ({
  children,
  scrollIndicator = null,
  isGradientBG = true,
}) => {
  return (
    <div className="relative flex justify-center items-center min-h-[100vh]">
      {isGradientBG && (
        <canvas
          className="absolute top-0 left-0 right-0 h-full z-10"
          id="gradient-canvas-form"
          data-transition-in
        />
      )}
      <ProgressBar scrollIndicator={scrollIndicator} />

      <div className="w-[96vw] h-[92vh] bg-white m-auto flex justify-center items-center z-10 rounded-3xl glass-card">
        {children}
      </div>
    </div>
  )
}

export const DotIndicators = ({
  totalQuestions,
  currentPage,
  setCurrentPage,
  isSubmitted,
}) => {
  if (isSubmitted) return null

  return (
    <div className="hidden md:flex absolute z-50 flex-col items-center justify-center space-y-3 right-5 top-1/2 translate-y-[-50%]">
      {Array.from({ length: totalQuestions }).map((_, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              setCurrentPage(index + 1)
            }}
            className={`transition-all duration-300 ease-in-out cursor-pointer ${
              index === currentPage - 1
                ? 'bg-white w-[10px] h-[10px] border border-gray-300'
                : 'bg-gray-400 w-[8px] h-[8px] border border-gray-200'
            } rounded-full`}
          />
        )
      })}
    </div>
  )
}

export const ArrowNavigator = ({
  handleNext,
  handlePrev,
  isFirstPage,
  isLastPage,
  isSubmitted,
}) => {
  if (isSubmitted) return null

  return (
    <div className="absolute z-50 flex flex-row divide-x-2 divide-gray-300 rounded-lg shadow-3xl bottom-5 right-5">
      <button
        disabled={isFirstPage}
        onClick={handlePrev}
        className="p-2 bg-white rounded-l-lg cursor-pointer disabled:bg-gray-200 disabled:cursor-default disabled:hover:text-black hover:bg-gradient-to-r hover:to-gradient-blue-one hover:from-gradient-blue-two hover:text-white"
      >
        <ChevronIcon />
      </button>
      <button
        disabled={isLastPage}
        onClick={handleNext}
        className="p-2 bg-white rounded-r-lg cursor-pointer disabled:hover:bg-gray-200 disabled:bg-gray-200 disabled:hover:text-black disabled:cursor-default hover:bg-gradient-to-r hover:to-gradient-blue-one hover:from-gradient-blue-two hover:text-white"
      >
        <ChevronIcon className="w-4 h-4 rotate-180" />
      </button>
    </div>
  )
}

export const PreviewBanner = () => {
  return (
    <div className="fixed w-full top-10 z-100">
      <div className="flex items-center justify-center max-w-xs px-3 py-2 mx-auto bg-violet-200 rounded-lg sm:px-5 md:max-w-sm lg:max-w-md text-violet-800">
        <span>
          <WarningIcon />
        </span>
        <p className="mx-auto ml-2 text-sm tracking-wide text-left font-body">
          Preview Mode. Answers won&apos;t be submitted. Use the link without
          the preview parameter for submissions.
        </p>
      </div>
    </div>
  )
}
