import { ChangeEventHandler, FC, useEffect, useRef } from 'react'
import {
  ResponseError,
  useValidation,
  ValidationStatus,
} from '../validation-provider'

type Props = {
  questionId: string
  properties: any
  onChange: (newValue: string) => void
  value: string
  autoFocus?: boolean
}

const ShortText: FC<Props> = ({
  questionId,
  properties,
  onChange,
  value,
  autoFocus,
}) => {
  const { placeholder, maxCharacters, isMaxLengthSpecified } = properties
  const ref = useRef<HTMLInputElement>(null)
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    onChange(e.target.value)

  const { isRequired } = properties
  const { setValidationStatus } = useValidation()

  useEffect(() => {
    if (autoFocus) ref.current?.focus()
  }, [autoFocus])

  useEffect(() => {
    if (isRequired) {
      setValidationStatus({
        id: questionId,
        newStatus: ValidationStatus.ERROR,
        error: ResponseError.REQUIRED,
      })
    } else {
      setValidationStatus({ id: questionId, newStatus: ValidationStatus.VALID })
    }
  }, [isRequired, questionId, setValidationStatus])

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    handleChange(e)
    const value = e.target.value

    if (isRequired) {
      if (!value) {
        setValidationStatus({
          id: questionId,
          newStatus: ValidationStatus.ERROR,
          error: ResponseError.REQUIRED,
        })
        return
      }

      setValidationStatus({
        id: questionId,
        newStatus: ValidationStatus.VALID,
      })
    }
  }

  return (
    <input
      ref={ref}
      onChange={handleInputChange}
      value={value}
      placeholder={placeholder}
      maxLength={isMaxLengthSpecified ? maxCharacters : undefined}
      type="text"
      className="bg-white rounded-lg border border-gray-50 shadow-sm max-w-lg w-full transition-all duration-100 focus:outline-none focus:ring-transparent focus:border-gray-300 hover:border-gray-400 h-[60px]"
    />
  )
}

export default ShortText
