import { ChangeEventHandler, ReactElement, useEffect, useState } from 'react'
import {
  ResponseError,
  useValidation,
  ValidationStatus,
} from '../validation-provider'

const UploadIcon = ({
  className = '',
}: {
  className?: string
}): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`w-8 h-8 ${className}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={1.5}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
    />
  </svg>
)

type Props = {
  questionId?: string
  properties?: any
  onChange?: (file: File) => void
  value?: File
  className?: string
  disabled?: boolean
}

const FileUploader = ({
  questionId,
  properties = {},
  onChange = () => {},
  value,
  className = '',
  disabled = false,
}: Props): ReactElement => {
  const { isRequired } = properties
  const [fileHref, setFileHref] = useState('')

  const { setValidationStatus } = useValidation()

  useEffect(() => {
    if (value) {
      setFileHref(window.URL.createObjectURL(value))
    }
  }, [value])

  useEffect(() => {
    if (isRequired) {
      setValidationStatus({
        id: questionId,
        newStatus: ValidationStatus.ERROR,
        error: ResponseError.REQUIRED,
      })
    } else {
      setValidationStatus({ id: questionId, newStatus: ValidationStatus.VALID })
    }
  }, [isRequired, questionId, setValidationStatus])

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (e.target.files.length === 0) return
    const file = e.target.files[0]
    onChange(file)
    setValidationStatus({ id: questionId, newStatus: ValidationStatus.VALID })
  }

  return (
    <label
      className={`group relative flex flex-col items-center gap-y-4 w-full max-w-lg border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 duration-200 transition-colors ${className}`}
    >
      <input
        type="file"
        className="sr-only"
        disabled={disabled}
        onChange={handleChange}
        max={1}
      />
      <UploadIcon className="text-gray-300 group-hover:text-gray-400 duration-200 transition-colors" />
      {fileHref ? (
        <a
          href={fileHref}
          target="_blank"
          className="text-blue-500 hover:text-blue-600 transition-colors duration-200"
          rel="noreferrer"
        >
          File uploaded
        </a>
      ) : (
        <span className="mt-2 block text-sm font-medium text-gray-600 group-hover:text-gray-900 font-body duration-200 transition-colors">
          Upload file
        </span>
      )}
    </label>
  )
}

export default FileUploader
