export const GradientText = ({
  children,
  colors = 'from-violet-600 to-pink-600',
}) => {
  return (
    <span
      className={`text-transparent bg-clip-text bg-gradient-to-r ${colors}`}
    >
      {children}
    </span>
  )
}
